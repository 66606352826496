import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const formatBytes = (bytes: number): string => {
  const units = ['B', 'KB', 'MB', 'GB', 'TB'];
  let i = 0;
  while (bytes >= 1024 && i < units.length - 1) {
    bytes /= 1024;
    i++;
  }
  //   return `${bytes?.toFixed(2)} ${units[i]}`;
};

// Outputs: "24 August 2024"
export const formatDateCustom = (
  isoString: string,
  month?: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow' | undefined
) => {
  if (!isoString) return '-';

  const date = new Date(isoString);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    return '-';
  }

  return date.toLocaleDateString('en-GB', {
    day: 'numeric',
    month: month ? month : 'long',
    year: 'numeric',
  });
};

// Output: 15 Oct 2024 08:12
export const formatTimestamp = (timestamp: string): string => {
  const date = new Date(timestamp);

  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  };

  return date.toLocaleDateString('en-GB', options).replace(',', '');
};

// Outputs: "10 days ago"
export const timeAgo = (isoString: string): string => {
  const date = new Date(isoString);
  const now = new Date();

  const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);

  const secondsInMinute = 60;
  const secondsInHour = 60 * secondsInMinute;
  const secondsInDay = 24 * secondsInHour;

  if (diffInSeconds < secondsInMinute) {
    return `${diffInSeconds} seconds ago`;
  } else if (diffInSeconds < secondsInHour) {
    const minutes = Math.floor(diffInSeconds / secondsInMinute);
    return `${minutes} minutes ago`;
  } else if (diffInSeconds < secondsInDay) {
    const hours = Math.floor(diffInSeconds / secondsInHour);
    return `${hours} hours ago`;
  } else {
    const days = Math.floor(diffInSeconds / secondsInDay);
    return `${days} days ago`;
  }
};

// output: "11:35 AM"
export const convertToLocalTime = (isoDate: string): string => {
  const date = new Date(isoDate);

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get device's time zone dynamically

  const options: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone: timeZone,
  };

  return date.toLocaleTimeString('en-US', options);
};

export function formatDateOverdue(targetDate: string | Date): string {
  const currentDate = new Date();
  const target = new Date(targetDate);

  const differenceInTime = currentDate.getTime() - target.getTime();

  const differenceInDays = Math.floor(differenceInTime / (1000 * 60 * 60 * 24));
  const differenceInHours = Math.floor(
    (differenceInTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );

  if (differenceInDays > 0) {
    return `Overdue by ${differenceInDays} day${
      differenceInDays > 1 ? 's' : ''
    }`;
  } else if (differenceInHours > 0) {
    return `Overdue by ${differenceInHours} hour${
      differenceInHours > 1 ? 's' : ''
    }`;
  }

  return '';
}

export function checkIfDateOverdue(
  targetDate: string | Date | null | undefined
): boolean | string {
  if (!targetDate) return false;

  const currentDate = new Date();
  const target = new Date(targetDate);

  const differenceInTime = currentDate.getTime() - target.getTime();

  return differenceInTime > 0;
}
